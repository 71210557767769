import React from "react";
import CenteredHeroSection from "../components/sections/CenteredHeroSection";
import StandardSection from "../components/sections/StandardSection";
import { DefaultLayout } from "../components/layouts/DefaultLayout";
import Button from "../components/elements/Button";
import { Fade } from "react-awesome-reveal";
import ProjectsList from "../components/elements/ProjectsList";
import { Helmet } from "react-helmet";

export default function IndexPage() {
	return (
		<DefaultLayout>
			<Helmet>
				<link rel="canonical" href="https://www.cosmoslundell.com/" />
			</Helmet>

			<CenteredHeroSection scrollArrow>
				<p className="text-xl text-gray-800 mb-2 dark:text-gray-400">Hello there, I am</p>

				<h1 className="text-5xl sm:text-6xl lg:text-7xl mb-4 font-extrabold text-red-500">Cosmos Lundell</h1>

				<h2 className="text-2xl lg:text-3xl text-gray-800 font-semibold dark:text-gray-200">
					I'm a Full Stack Web Developer who loves everything tech.
				</h2>

				<div>
					<ul className="mt-4 -m-1">
						{["Swedish", "JS", "Angular 2+", "React", "PHP", "Node.js", "MySQL", "MongoDB"].map((label, index) => (
							<li
								key={index}
								className="inline-block m-1 px-4 py-2 font-semibold rounded-full text-sm border-2 border-slate-800 text-gray-900 bg-transparent hover:bg-gray-700 hover:bg-opacity-20 transition-colors dark:border-2 dark:text-gray-800 dark:bg-gray-200 dark:hover:bg-gray-400 dark:hover:text-gray-900"
							>
								{label}
							</li>
						))}
					</ul>
				</div>

				<div className="mt-8 space-x-4">
					<Button href="#contact" aria-label="Get in touch">
						Get in touch
					</Button>

					<Button href="#projects" aria-label="Projects">
						Projects
					</Button>
				</div>
			</CenteredHeroSection>

			<StandardSection id="projects">
				<Fade direction="up" triggerOnce cascade duration={500}>
					<h3 className="text-5xl sm:text-6xl lg:text-7xl mb-4 font-extrabold text-red-500">Projects</h3>

					<h4 className="text-2xl lg:text-3xl text-gray-800 font-semibold dark:text-gray-200 mb-2">What I've worked on and what I'm working on.</h4>

					<p className="text-xl text-gray-800 font-light dark:text-gray-200">Scroll down to see a couple of projects.</p>
				</Fade>
			</StandardSection>

			<CenteredHeroSection>
				<ProjectsList />
			</CenteredHeroSection>

			<CenteredHeroSection id="about">
				<div className="grid grid-cols-1 sm:grid-cols-2">
					<div>
						<Fade direction="up" triggerOnce cascade duration={500}>
							<h3 className="text-5xl sm:text-6xl lg:text-7xl mb-4 font-extrabold text-red-500">About me</h3>

							<h4 className="text-2xl lg:text-3xl text-gray-800 font-semibold dark:text-gray-200 mb-4 sm:mb-0">A little something about me!</h4>
						</Fade>
					</div>

					<div>
						<Fade direction="up" triggerOnce cascade duration={500} delay={500}>
							<p className="text-xl text-gray-800 font-normal leading-normal dark:text-gray-200 mb-4">
								Born and raised in Hässleholm, a town in southern Sweden. I believe in taking care of myself, in a balanced diet and a rigorous
								exercise routine. When I'm not developing software, I like to hang out with friends and family.
							</p>
							<p className="text-xl text-gray-800 font-normal leading-normal dark:text-gray-200 mb-4">
								From an early age I've found computers and technology interesting. My journey began with experimenting and developing video
								games, as it did for many other programmers. Somewhere along the way, I discovered the joy of developing websites and systems,
								ranging from small tools to nearly planet scale business applications.
							</p>
							<p className="text-xl text-gray-800 font-normal leading-normal dark:text-gray-200 mb-4">
								In 2017, I started my first job as a web developer at a web agency, where I learned to both develop large-scale business
								applications and to work closer with the end user, setting requirements and meeting their needs. In 2020, I switched to
								<span>&nbsp;</span>
								<a href="https://www.presto.se" rel="noopener noreferrer nofollow">
									<strong className="font-medium underline underline-offset-2">Presto AB</strong>
								</a>
								, where I'm currently working as a System Developer.
							</p>
							<p className="text-xl text-gray-800 font-light dark:text-gray-200 mb-4 last:mb-0"></p>
						</Fade>
					</div>
				</div>
			</CenteredHeroSection>

			<CenteredHeroSection id="contact">
				<div className="grid grid-cols-1 sm:grid-cols-2">
					<div>
						<Fade direction="up" triggerOnce cascade duration={500}>
							<h3 className="text-5xl sm:text-6xl lg:text-7xl mb-4 font-extrabold text-red-500">Contact</h3>

							<p className="text-xl lg:text-2xl text-gray-800 font-light dark:text-gray-200 mb-4 sm:mb-0">
								If you'd like to get in touch with me,
								<br />
								please fill out the form and I'll get back to you as soon as possible!
							</p>
						</Fade>
					</div>

					<div className="text-center">
						<Fade direction="up" triggerOnce cascade delay={500}>
							<form action="https://formspree.io/f/mzbkoebp" method="POST" className="space-y-3">
								<input
									className="block w-full rounded bg-gray-200 p-3 text-gray-900 placeholder:text-gray-500 hover:placeholder:text-gray-500 dark:bg-gray-100 dark:text-gray-900 dark:placeholder:text-gray-500"
									type="text"
									name="name"
									placeholder="Name"
									autoComplete="name"
									required
								/>
								<input
									className="block w-full rounded bg-gray-200 p-3 text-gray-900 placeholder:text-gray-500 hover:placeholder:text-gray-500 dark:bg-gray-100 dark:text-gray-900 dark:placeholder:text-gray-500"
									type="email"
									name="_replyto"
									placeholder="Email"
									autoComplete="email"
									required
								/>
								<textarea
									className="block w-full rounded bg-gray-200 resize-none p-3 text-gray-900 placeholder:text-gray-500 hover:placeholder:text-gray-500 dark:bg-gray-100 dark:text-gray-900 dark:placeholder:text-gray-500"
									name="message"
									placeholder="Message"
									rows={6}
									autoComplete="off"
									required
								></textarea>
								<button
									className="font-semibold px-4 py-2 hover:px-5 border-2 block w-full rounded bg-gray-800 border-gray-800 text-white hover:border-gray-700 hover:bg-gray-700 active:border-gray-600 active:bg-gray-600 transition-all dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:active:bg-gray-500 dark:active:border-gray-500"
									type="submit"
								>
									Send
								</button>
							</form>
						</Fade>
					</div>
				</div>
			</CenteredHeroSection>
		</DefaultLayout>
	);
}
