import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { FC } from "react";
import { Fade } from "react-awesome-reveal";
import Button from "../Button";

interface IProject {
  title: string;
  technologies: string[];
  links: IProjectLink[];
  id: string;
  description: string;
  image: any;
}

interface IProjectLink {
  label: string;
  url: string;
}

const ProjectsList: FC = () => {
  const data = useStaticQuery(graphql`
    query AllActiveProjects {
      projects: allProjectsJson(
        filter: { active: { eq: true } }
        sort: { fields: id, order: DESC }
      ) {
        edges {
          node {
            title
            technologies
            links {
              label
              url
            }
            id
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }
  `);

  const projects: IProject[] = data.projects.edges.map(
    (edge: any) => edge.node
  );

  return (
    <div className="my-16 flex flex-col">
      <Fade duration={250} cascade triggerOnce>
        {projects.map((project) => (
          <div
            className="pb-16 sm:pb-0 mb-16 sm:mb-0 sm:min-h-screen grid grid-cols-1 sm:grid-cols-2 items-center gap-8 border-b border-b-gray-300 dark:border-b-gray-800"
            key={project.id}
          >
            {project.image && (
              <div>
                <a
                  draggable="false"
                  href={project.links[0].url}
                  aria-label="Visit website"
                >
                  <div className="relative overflow-hidden rounded-2xl shadow-xl hover:brightness-110 hover:opacity-75 transition">
                    <GatsbyImage
                      image={getImage(project.image)}
                      alt={project.title}
                    />
                  </div>
                </a>
              </div>
            )}

            <div>
              <h2 className="text-4xl lg:text-5xl text-gray-800 font-semibold dark:text-gray-200 mb-4">
                {project.title}
              </h2>

              <p className="text-gray-900 dark:text-white mb-4 font-light text-lg">
                {project.description}
              </p>

              <div className="space-x-2">
                {project.links.map((link, linkIndex) => (
                  <Button
                    href={link.url}
                    isExternal
                    key={linkIndex}
                    rel="noopener nofollow noreferrer"
                    target="_blank"
                    aria-label={link.label}
                  >
                    {link.label}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        ))}
      </Fade>
    </div>
  );
};

export default React.memo(ProjectsList);
